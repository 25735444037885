.landing-header {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86%;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 80px;
    margin: auto;
    color: white;
    padding-bottom: 60px;
}

.landing-page {
    background-color: black;
}

.free-stock {
    -webkit-box-align: center;
    align-items: center;
    background-image: linear-gradient(74.84deg, rgb(0, 200, 5) 18.12%, rgb(195, 245, 60) 81.46%);
    background-size: cover;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    display: flex;
    font-size: 13px;
    height: 62px;
    justify-content: center;
    line-height: 18px;
    padding: 0px 25px;
    text-align: center;
}

.build-portfolio {
    background-color: var(--light-green);
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
}

.build-portfolio-top {
    display: flex;
    width: 100%;
}


.build-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 90px;
}

.huge-font{
    font-size: 52px;
    font-weight: 400;
}

.fa-info-circle {
    margin-right: 10px;
}

#landing-info{
    width: fit-content;
    background-color: transparent;
    border: 1px solid black;
    color: black !important;

}

#log-in{
    color: black !important;

}

#limitations:hover{
    cursor: pointer;
}
