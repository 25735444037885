
.buying-power {
    text-align: center;
    padding: 1rem;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
}

.estimated-cost {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.market-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.market-price-label {
    color: var(--robin-hood-green)
}

.select-field {
    cursor: pointer;
}

.stock-owned {
    text-align: center;
}

.transact-button {
    margin: 1rem 0;
    padding: 14px 28px;
    font-weight: 600;
    background: var(--robin-hood-green);
    color: white;
    border: 0px;
    border-radius: 27px;
    height: 3rem;
    width: 100%;
}

.transact-button:hover {
    cursor: pointer;
}

.transact-field {
    margin-top: 1rem;
}

.transact-field-heading {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.transact-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.transact-form-container {
    /* position: sticky;
    top: 5rem;
    align-self: flex-start; */
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgb(25, 25, 25);
    height: 28rem;
    border: 1px solid var(--border-grey);
    border-radius: 5px;
    box-shadow: 1px 1px 1px var(--border-grey);
    width: 17rem;
    font-size: 14px;
}


/* Transactions page */

/* .transactions-page{
    color: white;
    background-color: black;
    height: fit-content;
    height: 88vh;
} */
 #purchased-stock{
    color: red;
 }
#sold-stock{
    color: var(--robin-hood-green);
}

#stock-emoji {
    transform: rotateX(-180deg);
}

#portfolio-btn:hover{
    cursor: pointer;
    color: var(--robin-hood-green);
    text-decoration: underline;
}
#portfolio-btn{
    font-size: 20px;
    position: absolute;
    font-weight: 600;
}

