a {
    color: white;
}

h1 {
    font-weight: 500;
}

h2 {
    font-weight: 500;
}

.about-block {
    margin-top: 1rem;
}

.about-fields {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    font-size: 14px;
}

.about-text {
    margin-top: 3rem;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 1rem;
}

.news-headline {
    font-size: 18px;
    margin-bottom: 5px;
}
.news-image {
    width: 100px;
    height: 60px;
}

.news-source {
    margin-bottom: 5px;
}

.news-story {
    color: white;
}

.news-summary {
    margin-bottom: 2rem;
}

.stock-about {
    padding-top: 1rem;
    width: 100%;
}

.stock-chart {
    margin-top: 2rem;
}

.stock-details-page {
    color: white;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 55%;
    gap: 1rem;
}

.stock-details-top {
    width: 100%;
}

.stock-details-wrapper {
    background-color: black;
    display: flex;
    padding: 1rem;
}

.stock-label {
    font-weight: bold;
}

.stock-negative {
    color: red;
}

.stock-news {
    border-bottom: 1px solid white;
    margin: 1rem 0;
}

.stock-positive {
    color: var(--robin-hood-green);
}

.toggle-description {
    color: var(--robin-hood-green);
    background: none;
    border:none;
    padding: 0;
    cursor: pointer;
}

.toggle-view {
    color: var(--robin-hood-green);
    background: none;
    border:none;
    padding: 0;
    cursor: pointer;
}

 .transactions {
    position: fixed;
    right: 4rem;
    top: 5rem;
    align-self: flex-start;
    /* color: white; */
    /* padding: 1rem; */
    margin: 1rem;
    /* background-color: rgb(25, 25, 25); */
    height: 28rem;
    border: 1px solid var(--border-grey);
    border-radius: 5px;
    box-shadow: 1px 1px 1px var(--border-grey);
    width: 17rem;
    font-size: 14px;
}

#add-to-list{
    width: 100%;
    background-color: var(--robin-hood-green);
    color: white;
    text-align: center;
}
