.portfolio-page {
    display: flex;
    padding-top: 30px;
    background-color: black;
    color: white;
    width: 100%;
    padding-left: 20px;
    height: 1050px;


}
/* #only-portfolio{
    height: fit-content;
}
#only-portfolio-{
    height: fit-content;
    margin-bottom: 30px;
} */

.portfolio {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 900px;
}

.portfolio-watchlist {
    border: 1px solid var(--border-grey);
    padding: 20px;
    border-radius: 4px;
    height: 250px;
    /* overflow: scroll; */
    position: fixed;
    right: 20px;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 30%;
    padding-bottom: 45px;
}
.portfolio-watchlist::-webkit-scrollbar {
    display: none;
}

.transfer {
    display: flex;

    background-color: #989b9d;
    padding: 15px;
    border-radius: 3px;
    position: relative;

}

.tool-tip-portfolio {
    width: 65%;
}

.transfer-left {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

#transfer-img {
    position: absolute;
    right: 0px;
    bottom: 15px;
    width: 95px;
}

#transfer-money-btn {
    width: fit-content;
    padding: 6px 12px;
    margin-top: 20px;
}

.portfolio-graph-data {
    display: flex;
    flex-direction: column;
    gap: .2em;
}

.table-container {
    height: 600px;
    overflow: scroll;
    width: 65%;
    border: 1px solid var(--border-grey);
    position: relative;
    /* scroll-behavior: smooth; */
}
