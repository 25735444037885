/* TODO Add site wide styles */
:root{
    --dark-moss-green: #47632dff;
        --black-olive: #30341dff;
        --rufous: #a12d1aff;
        --saffron: rgb(255, 199, 87);
        --lawn-green: #86ff44ff;
        --light-blue: #82c8d2;
        --light-green: #c3f53c;
        --border-grey: #30363a;
        --robin-hood-green: #00c805;
}


*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.round-buttons {
    width: fit-content;
}

.pawprints {
    background-repeat: repeat;
    background-size: contain;
    width: 100%;
    /* adjust the width of the div as needed */
    height: 200px;
    /* adjust the height of the div as needed */
}

footer {
    display: flex;
    padding: 10px;
    background-color: var(--saffron);
    gap: 90px;
    width: 100%;
    justify-content: space-between;
    padding-top: 20px;
}

.footer-links>*,
a {
    color: grey;
    font-size: 13px;
}

.footer-links>a:hover {
    color: var(--robin-hood-green);
    text-decoration: underline;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.footer-headings {
    font-weight: 500;
    margin-bottom: 5px;
}

#robin-hood-link:hover {
    color: var(--robin-hood-green);
    text-decoration: underline;

}

.footer-divider {
    width: 4px;
    background-color: #bfbdbd;
    border-radius: 10px;
}
#fox-footer{
    width: 60px;
    position: relative;
    right: 30px;
}

#footer-logo{
    font-weight: 600;
    font-size: 16px;
    color: black;
    position: relative;
    right: 40px;
}

.discolsure-modal{
        width: 500px;
        height: 280px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;
        border: 10px solid var(--saffron);
        border-radius: 5px;
    }

        .discolsure-modal::-webkit-scrollbar {
            display: none;
        }
#disclaimers{
    font-size: 30px;
    color: #434343;
    text-decoration: underline;
}

.creators > a {
    font-size: 16px;
}
.creators > a:hover {
    color: var(--robin-hood-green);
    text-decoration: underline;
}

.disclosure:hover{
    color: grey;
    text-decoration: underline;
    cursor: pointer;
}

#root{
    height: 100vh;
    background-color: black;
}

.off-white{
    color: rgb(212, 212, 212);
    font-weight: 600;
}
.off-blue {
    color: rgb(96, 119, 145);
    font-weight: 600;
}
.form-errors{
    color: var(--rufous);
}
