.profile-dropdown {
  position: absolute;
  top: 3em;
  right: 1.5em;
  background-color: white;
  width: 18em;
  /* height: 10em; */
  border-radius: 6px;
  border: .25px lightgray solid;
  padding: 1em 1.5em 1em 1.5em;
  z-index: 2;
}
.account{
  color: white;
}

.account:hover {
  color: var(--lawn-green);
}

.profile {
  font-weight: 600;
}

#profile-highlight {
  color: var(--lawn-green);
  border-bottom: solid 2px var(--lawn-green);
}

.user-menu {
  list-style: none;
  padding: .3em 0 .3em 0;
}

.user-border {
  border-bottom: .5px lightgrey solid;
  margin-bottom: 1em;
  padding-bottom: .6em;
}

.nav-button {
  border: none;
  background-color: transparent;
  font-size: medium;
  cursor: pointer;
}

.hidden {
  display: none;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  height: 65px;
  border-bottom: 3px solid var(--saffron);
  background-color: black;
}

.nav-bar>li,
a {
  list-style: none;
  text-decoration: none;
}

#logo-li::marker {
  border-right: 3px solid var(--saffron);

}

.login-signup:hover {
  cursor: pointer;
}
.login-signup {
  background-color: var(--saffron);
  padding: 14px 28px;
  border-radius: 27px;
  font-weight: 600;
}

#sign-up {
  background-color: var(--saffron);
  color: black;
  font-size: 13px;
}

.signup-page {
  width: 100%;
  display: flex;
}

.signup-left {
  background-color: var(--light-blue);
  height: 100vh;
  width: 50%;
  padding: 36px 46px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

#signup-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 5px;
    background: rgb(128, 128, 128);
      background: linear-gradient(180deg, rgba(128, 128, 128, 1) 0%, rgba(173, 173, 173, 1) 55%, rgb(255, 199, 87) 100%);
}

.login-page {
  display: flex;
  background: rgb(128, 128, 128);
    background: linear-gradient(180deg, rgba(128, 128, 128, 1) 0%, rgba(173, 173, 173, 1) 55%, rgb(255, 199, 87) 100%);
}

#login-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 5px;
  height: 87vh;

}

.login-inputs {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.forgot-info{
  font-weight: 500;
  text-decoration: underline;
}

.stockSearch {
  height: 3em;
  width: 30em;
  padding-left: 2.5em;
  border: .25px solid lightgrey;
  border-radius: 5px;
  font-weight: 600;
}

.stockSearch:focus {
  outline: none;
  border: .5px rgb(145, 145, 145) solid;
}

#hidden-dropdownshow {
  display: none
}

.stock-dropdownshow {
  top: 3.5em;
  background-color: white;
  padding: 1.5em 1em 1.5em 1em;
  border-radius: 10px;
  position: fixed;
  left: 12.1em;
  width: 25.2em;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
  cursor: default;
  z-index: 1000000;
}

.search-home {
  display: flex;
  align-items: center;
  gap: 5em;
}

.make-greenshow::first-letter {
  color: var(--light-green);
  font-weight: 600;
}

.res-text {
  color: black;
}

.search-res {
  display: flex;
  justify-content: space-between;
}

#itemshidden {
  display: none;
}

.fa-search {
  position: relative;
  left: 1.5em;
  top: 1px;
  color: rgb(118 , 118, 118);
}

.user-menu-nav {
  color: black;
  text-decoration: none;
}

.nav-login-signup {
  display: flex;
  justify-content: right;
  gap: 1em;
}

.remove-dot {
  list-style-type: none;
}
#nav-logo{
  color: var(--saffron) !important;
  font-weight: 500;
  position: relative;
}

#fox-image{
  width: 30px;
  position: absolute;
  bottom: -5px;
  right: -40px;
}

#nav-login{
  color: var(--saffron) !important;
  background-color: transparent;
  border: 1px solid var(--saffron);
  font-size: 13px;
}

#landing-four{
  color: var(--saffron);
  font-weight: 600;
}

#dropdown-links-{
  font-size: 16px;
}
