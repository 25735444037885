#watchlist-table {
    border-collapse: collapse;
    width: 100%;


}


.watch-List-Items::-webkit-scrollbar{
    display: none;
  }

.watch-List-Items {
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 20px;
}

#watchlist-table td,
#watchlist-table th {
    border-bottom: 1px solid #ddd;
    padding: 16px 0px;
}

#watchlist-table .table-row:hover {
    background-color: #898989;
    cursor: pointer;
}


.table-container::-webkit-scrollbar {
    display: none;
}
.table-row {
    position: relative;
}

.delete-stock {
    position: absolute;
    right: 10px;
}

.fa-trash:hover {
    color: red;
}

#watchlist-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: #898989;
}

.edit-watchlist {
    display: none;
}

.watchlist-clicked {
    display: block;
    position: absolute;
    right: 0px;
    top: 30px;
    background-color: white;
    color: black;
    width: 134px;
    padding: 5px;
    border-radius: 3px;
    z-index: 1000;
}

#watchlists-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: var(--border-grey) 1px solid;
    position: relative;
}

.list-name {
    font-size: 13px;
}

.watchlist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    position: relative;
}

.watchlist-left {
    color: white
}

.watchlist:hover {
    background-color: var(--border-grey);
}

.watchlist-icon {
    background-color: #1e2124;
    padding: 6px 2px;
    border-radius: 3px;
    font-size: 13px;
    margin-right: 8px;
}


.fa-ellipsis-h {
    margin-right: 20px;
}

.edit-icon {
    margin-right: 5px;
}

.delete-list:hover {
    color: red;
    cursor: pointer;
}

.lists-modal {
    background-color: #1E2124;
    width: 400px;
    position: relative;
    top: 0px;
    right: 0px;
    color: white;
}

.list-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.list-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid var(--robin-hood-green);
    position: relative;
}
#close-create-list{
    position: relative;
    top: 0;
}

.watchlist-med {
    font-size: 15px;
}

.watchlist-sm {
    font-size: 13px;
}

.watchlist-lrg {
    font-size: 16px;
}

#watchlist-modal:hover {
    background-color: transparent;
    cursor: pointer;
}

.all-watchlists-modal{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#watchlist-modal {
    justify-content: flex-start;
    gap: 15px;
}

.save-changes {
    text-align: center;
    background-color: var(--robin-hood-green);
    color: white;
}

.checked-list {
    background-color: var(--robin-hood-green);
}

.fa-check {
    color: white;
}


.watchlist-page-header {
    color: white;
    background-color: black;
    padding: 30px 0px 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 900px;

}

.fa-plus:hover {
    cursor: pointer;
    color: var(--robin-hood-green) !important;
}

.fa-ellipsis-h:hover {
    cursor: pointer;
}

#edit-list:hover {
    cursor: pointer;
}

.create-list-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    width: 400px;
}

#create-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.list-input > input{
    border: none;
    background-color: #eeeeeeae;
    padding: 5px;
    border-radius: 4px;
    width: 100%;
}
.list-input > p{
    padding: 3px 6px;
    margin-right: 10px;
    border: 1px #e0e0e0 solid;
    border-radius: 5px;
}
.list-input{
    display: flex;
    width: 90%;
    margin: 10px auto;
}

.list-btns{
    display: flex;
    width: 90%;
    margin: 0px auto;
    justify-content: flex-end;
    gap: 10px;
}

#list-cancel{
    background-color: transparent;
    border: 1px solid var(--robin-hood-green);
    color: var(--robin-hood-green);
}

.fa-times{
    padding: 10px;
    position: absolute;
    right: 0px;
    top: -10px;

}
.fa-times:hover{
    cursor: pointer;
    background-color: #e0e0e0;
    border-radius: 4px;
}

#user-name{
    font-size: 20px;
    position: relative;
}

#pos{
    color: var(--robin-hood-green);
}
#neg{
    color: red;
}

#delete-item{
    background-color: var(--rufous);
}

.empty-list{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
}
.empty-list img{
    position: relative;
    left: -100px;
    width: 90%;
}
